import { useContext, useState, useEffect } from 'react'
import { SurveyContext } from './surveyContext'
import { CheckboxPossibleAnswerTemplate } from '@talkadot/survey-component-library'

import * as Survey from './surveyHelpers'

const CheckboxPossibleAnswer = ({ possibleAnswer }) => {
  const { updateAnswerValue, surveyState, setOver18Value } =
    useContext(SurveyContext)
  const { checkedOver18 } = surveyState
  const [checked, setChecked] = useState(true)

  const isDisabled = Survey.isInputDisabledDueToAge(possibleAnswer, surveyState)

  useEffect(() => {
    initializeCheckedState()
  }, [possibleAnswer.answerValue])

  useEffect(() => {
    if (isDisabled) {
      updateAnswerValue(possibleAnswer, false)
    }
  }, [checkedOver18])

  const initializeCheckedState = () => {
    if (
      possibleAnswer.answerValue !== undefined &&
      possibleAnswer.answerValue !== null
    ) {
      setChecked(possibleAnswer.answerValue)
    } else if (possibleAnswer.answerType === Survey.ANSWER_TYPES.OVER_18) {
      // The first time this renders set it to false and disable other inputs
      updateCheckedState(false)
    } else {
      // The first time this renders update the possible answer to mirror the value
      updateAnswerValue(possibleAnswer, checked)
    }
  }

  const handleUpdate = (e) => {
    updateCheckedState(e.target.checked)
  }

  const updateCheckedState = (boolVal) => {
    if (possibleAnswer.answerType === Survey.ANSWER_TYPES.OVER_18) {
      setOver18Value(boolVal)
    }
    updateAnswerValue(possibleAnswer, boolVal)
    setChecked(boolVal)
  }

  return (
    <CheckboxPossibleAnswerTemplate
      checked={checked}
      disabled={isDisabled}
      handleChange={handleUpdate}
      content={possibleAnswer.content}
    />
  )
}

export default CheckboxPossibleAnswer
